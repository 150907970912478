import { Action, Subjects } from "@/libs/acl/ability";

export default [
  {
    title: "Management.Processes.Process_short",
    route: "seals/processes",
    icon: "AwardIcon",
    action: Action.Read,
    resource: Subjects.Process,
  },
  {
    title: "Management.Menu.Accounting",
    route: "accounting",
    icon: "FileTextIcon",
    action: Action.Read,
    resource: Subjects.Accounting,
  },
  {
    title: "Management.Menu.Administration",
    route: "seals",
    icon: "GlobeIcon",
    action: Action.Read,

    children: [
      {
        title: "Management.Areas.Areas",
        route: "seals/areas",
        icon: "TruckIcon",
        action: Action.Read,
        resource: Subjects.Areas,
      },
      {
        title: "Management.Customers.Customers",
        route: "customers",
        icon: "DatabaseIcon",
        action: Action.Read,
        resource: Subjects.Customers,
      },
      {
        title: "Management.Users.Users",
        route: "users",
        icon: "UserIcon",
        action: Action.Read,
        resource: Subjects.Users,
      },
      {
        title: "Management.Charges.Charges",
        route: "seals/charges",
        icon: "CreditCardIcon",
        action: Action.Read,
        resource: Subjects.Charges,
      },
      {
        title: "Management.Countries.Countries",
        route: "seals/countries",
        icon: "FlagIcon",
        action: Action.Read,
        resource: Subjects.Countries,
      },
      {
        title: "Management.SealDistricts.SealDistricts",
        route: "seals/sealdistricts",
        icon: "MapIcon",
        action: Action.Read,
        resource: Subjects.SealDistricts,
      },
      {
        title: "Management.Sorts.Sorts",
        route: "seals/sorts",
        icon: "ListIcon",
        action: Action.Read,
        resource: Subjects.Sorts,
      },
    ],
  },
];
